<template>
  <div
    class="landpage"
    id="landpage"
    :style="{
      paddingBottom: calPaddingBottom + 'rem',
    }">
    <Component
      v-for="(item, k) in components"
      :key="k"
      :ref="item.component_name"
      :is="item.component_name"
      :mark="item.component_mark"
      :value="configData"
      :bottom-fixed="configData.bottom_fixed"
      :form="form"
      :count-down="configData.count_down"
      :header-img="configData.header_img"
      :center-coupon-info="centerCouponInfo"
      :pay-type-config.sync="config"
      :product-price="product_price"
      :is-cq="isCq"
      @cancelpay="cancelpay"
      @closePopup="closePopup"
      @clickConcealGuard="clickConcealGuard"
      @detentionpay="detentionSubmit"
      @formsubmit="handleSubmit"
      @concealGuardConfirm="concealGuardConfirm"
      @saveAddressInfo="handleAddressInfo"></Component>
    <SelPayType @change="submit" :config.sync="config"></SelPayType>
    <VanPopup
      v-if="center_coupon_id"
      v-model="showRedPocket"
      class="redPocket animateRedPocket"
      :close-on-click-overlay="false">
      <div class="red-content animateRedContent">
        <div class="red-content__title">您的折扣红包已到账</div>
        <img
          src="//wm-front-common.weimiaocaishang.com/wm-front-landing-page-mobile/redPocketBg1.png"
          alt="" />
        <div class="red-content__price">
          {{ centerCouponInfo.rule.discount }}
          <div class="red-content__yuan"></div>
        </div>
      </div>
    </VanPopup>
    <VanPopup v-else v-model="showRedEnvelope">
      <div>开屏红包</div>
    </VanPopup>
    <FeedbackEtrance v-if="!isCq" :scene-id="4" />
    <AgreementWrap
      ref="agreementWrap"
      @confirm="confirmAgreement"
      @checkAgreement="clickConcealGuard" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RecentBuy from '../components/RecentBuy'
import HeaderImg from '../components/HeaderImg'
import CountDown from '../components/CountDown'
import FormPay from '../components/FormPay'
import DetailImg from '../components/DetailImg'
import BottomFixed from '../components/BottomFixed'
import GroupBuy from '../components/GroupBuy'
import DetentionDialog from '../components/DetentionDialog'
import FormPop from '../components/FormPop'
import SelPayType from '../components/SelPayType'
import ICPRecord from '../components/ICPRecord'
import ConcealGuardDialog from '../components/ConcealGuardDialog'
import { path } from '../config/path'
import { uploadImagePercent } from '@/utils/imgInViewUploadPoint'
import FeedbackEtrance from '../components/Feedback/FeedbackEtrance.vue'
import AgreementWrap from '../components/AgreementWrap.vue'
import RedEnvelope from '../components/RedEnvelope.vue'
import FormPopNew from '../components/FormPopNew.vue'
import DetentionDialogAgain from '../components/DetentionDialogAgain.vue'
import GroupBuyNew from '../components/GroupBuyNew.vue'
import JumptoMiniapp from '../components/JumptoMiniapp.vue'
import EasyIm from '../components/EasyIm.vue'
import Address from '../components/Address.vue'

import {
  getSearch,
  vertifyPhoneCode,
  loadJs,
  evil,
  setDocumentTitle,
  scrollView
} from '@/utils/index'
import { codeMap } from '@/views/config/ErrorMsg'
import { defaultPageId, miniEnv } from '@/config'
import { LANDING_PATH_MAP, PAY_TYPE_MAP, CQ_BOUGHT_CODE, PATH_ID, COURSE_PRODUCT_SN } from '@/constant'
import { payType } from '@/mixins/payType'
import { DC } from '@/utils/device'

/* eslint-disable camelcase */
export default {
  components: {
    RecentBuy,
    HeaderImg,
    CountDown,
    FormPay,
    DetailImg,
    BottomFixed,
    GroupBuy,
    DetentionDialog,
    FormPop,
    SelPayType,
    ICPRecord,
    ConcealGuardDialog,
    FeedbackEtrance,
    AgreementWrap,
    RedEnvelope,
    DetentionDialogAgain,
    FormPopNew,
    GroupBuyNew,
    JumptoMiniapp,
    EasyIm,
    Address
  },
  mixins: [payType],
  data () {
    return {
      pageStatusTimer: null,
      intervel: 1000 * 3,
      pathId: null, // 路径id
      product_sn_key: null, // 课程映射id
      components: [], // 默认配置
      configData: {
        jsApi: [],
        component: [],
        showDialog: false, // 挽留弹窗
        showDialogAgain: false, // 二次弹窗
        showConcealGuardDialog: false, // 隐私协议弹窗
        showPop: false, // 表单pop
        showPopNew: false, // 新表单弹窗
        header_img: {}, // 头图
        detail_img: [], // 详情图
        form_pay: {}, // 表单支付配置
        bottom_fixed: {}, // 底部悬浮栏
        ICP_record: {}, // 底部悬浮栏
        count_down: {}, // 倒计时
        recent_buy: {}, // 最近购买
        jumpto_miniapp: {}, // 跳转小程序
        detention_dialog: {
          detention_copy: '', // 挽留文案
          detention_bg: [], // 内嵌图
          abandon_btn_copy: '', // 放弃文案
          pay_btn_copy: '' // 支付文案
        },
        // 二次挽留弹窗
        detention_dialog_again: {
          detention_copy: '', // 挽留文案
          detention_bg: [], // 内嵌图
          abandon_btn_copy: '', // 放弃文案
          pay_btn_copy: '' // 支付文案
        },
        red_envelope: {}, // 开屏红包
        new_form_pop: {}, // 新版支付弹窗
        orderNum: '' // 订单号
      }, // 当前落地页
      form: {
        phone: localStorage.getItem('phone') || getSearch('phone'), // 用户输入手机号
        testCode: '', // 验证码
        checked: false,
        area_code: '' || getSearch('area_code')
      },
      config: {
        show: false, // 显示支付方式
        payType: localStorage.getItem('payType') // 用户所选支付方式
      },
      abResult: {},
      brandId: 9,
      coupon_id: '',
      center_coupon_id: '', // 商品中心优惠券
      centerCouponInfo: {}, // 商品中心优惠券详情
      showRedPocket: false, // 优惠券红包弹窗
      showRedEnvelope: false, // 开屏红包弹窗
      heartbeat: null, // 心跳上报
      product_price: null,
      loading: null, // loading 3秒
      timeout: null, // 3秒出现挽留弹窗
      showAgreement: false,
      submitting: false, // 提交中
      addressInfo: null,
      device_info: null // 设备信息
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    calPaddingBottom () {
      return this.configData.bottom_fixed.is_show
        ? this.configData.bottom_fixed.bottom_bg.height / 75
        : 0
    },
    payTypes () {
      return this.configData.form_pay.pay_type.map((item) => {
        return JSON.parse(item)
      })
    },
    isCq () {
      // 是否是长轻的商品
      return [11, 19].includes(this.brandId)
    }
  },
  async created () {
    this.$log.update({ mid: this.userInfo?.mid || 0 })
    const checkOrder = getSearch('checkOrder')
    const alternate = {
      ...(JSON.parse(getSearch('alternate')) || {}),
      ua: navigator.userAgent
    }
    this.$log.update({
      alternate: alternate
    })
    const mgTv = sessionStorage.getItem('mga_report')
    if (mgTv && checkOrder) {
      this.changeQuery('mga_report', mgTv)
    }
    await this.getData()
    this.initBaseCode()
    this.$log.show({
      current_page: location.href,
      module_ori: '基于页面',
      module_part: `${this.$route.meta.module_part}_${getSearch('id')}`,
      alternate: alternate
    })
    this.heartbeat = this.$log.heartbeat(
      {
        current_page: location.href,
        module_ori: '基于页面',
        module_part: `106_tf_h5_00035_${getSearch('id')}`,
        alternate: alternate
      },
      5000,
      0.05
    )
    this.heartbeat.start()
    this.$log.scroll({
      current_page: location.href,
      module_ori: '基于页面',
      module_part: `106_tf_h5_00036_${getSearch('id')}`,
      wrapper: window,
      delay: 300,
      alternate: alternate
    })

    // 是否显示优惠券
    if (this.center_coupon_id && !checkOrder) {
      this.showRedPocket = true
      setTimeout(() => {
        this.showRedPocket = false
      }, 2.2 * 1000)
    }
    // 轮询购买状态
    if (checkOrder) {
      this.changeQuery('checkOrder')
      this.pullBuyStatus()
    }
    console.log('components', this.components)
    // 设备信息
    this.device_info = {
      model: DC.getBrandModel(),
      platform: DC.getOsName()
    }
  },
  mounted () {
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        console.log('页面挂起')
      } else {
        const checkOrder = getSearch('checkOrder')
        // 轮询购买状态
        if (checkOrder) {
          this.changeQuery('checkOrder')
          this.pullBuyStatus()
        }
      }
    })
  },
  beforeDestroy () {
    clearTimeout(this.pageStatusTimer)
    this.timeout && clearTimeout(this.timeout)
    this.heartbeat.close()
  },
  methods: {
    // 跳转支付成功页面
    async goSuccess (order_num) {
      const phone = this.form.phone || localStorage.getItem('phone') || getSearch('phone')
      // 地区code
      const areaCode = this.form.area_code || localStorage.getItem('area_code') || getSearch('area_code')

      let routerName = ''
      const data = await this.$apis.landPage.getOneByOrderNum({
        order_num,
        phone,
        area_code: areaCode
      })
      const isGroup = [20, 22].includes(Number(data.resource_type)) // 是否是加群
      if ([11, 19].includes(this.brandId)) {
        const { site_id, message_id, activity_id, id } = getSearch()
        const params = {
          site_id,
          message_id,
          activity_id,
          id,
          phone,
          area_code: areaCode,
          order_num,
          productSn: this.product_sn_key
        }

        this.$apis.landPage
          .getMiniHref({
            path: 'pages/fenban/index',
            // appid: changqingAppid,
            appid_group: 3, // ab配置，轮询分配小程序
            env_version: miniEnv,
            query: {
              ...params
            }
          })
          .then((res) => {
            this.$router.push({
              name: 'CQPaySuccess',
              query: {
                ...getSearch(),
                ...params,
                path_id: this.configData.path_id
              }
            })
          })
          .catch(() => {
            this.$router.push({
              name: 'CQPaySuccessH5',
              query: {
                ...getSearch(),
                ...params
              }
            })
          })
        return
      }
      if ([16, 21, 27, 28, 31].includes(this.brandId)) {
        const data = await this.$apis.landPage.getGroup({
          domain: 'guanlifenban2',
          abKey: 'guanlifenban',
          factor: order_num,
          whiteParam: phone
        })
        routerName =
          data.group === 'A' ? 'ManagePaySuccess' : 'ManagePaySuccessH5'
        this.$router.push({
          name: routerName,
          query: {
            ...this.$route.query,
            phone,
            order_num
          }
        })
        return
      }
      if ([18, 20, 25].includes(this.brandId)) {
        routerName = 'YuerPaySuccess'
        this.$router.push({
          name: routerName,
          query: {
            ...this.$route.query,
            phone,
            order_num
          }
        })
        return
      }
      if ([23, 24].includes(this.brandId)) {
        routerName = 'MeiZhiPaySuccess'
        this.$router.push({
          name: routerName,
          query: {
            ...this.$route.query,
            phone,
            order_num
          }
        })
        return
      }
      console.log(routerName)
      const hash = isGroup ? '/GuidGroup' : '/GuideClass'
      const targetUrl = new URL(window.location.href)
      targetUrl.pathname = '/customer/'
      targetUrl.hash = hash
      // if (typeId) {
      //   targetUrl.searchParams.set('typeId', typeId)
      // }
      targetUrl.searchParams.set('phone', phone)
      targetUrl.searchParams.set('order_num', order_num)
      targetUrl.searchParams.set('is_group', isGroup)
      targetUrl.searchParams.set('product_price', this.product_price)
      targetUrl.searchParams.set('brandId', this.brandId)
      console.log('targetUrl', targetUrl)
      window.location.href = targetUrl
    },
    // 加载jsapi基础代码
    initBaseCode () {
      const apiList = this.configData.jsApi
      apiList.forEach((item) => {
        loadJs(item.baseCode)
      })
    },
    // 执行转换代码
    runConvertCode () {
      const apiList = this.configData.jsApi
      apiList.forEach((item) => {
        console.log(item.convertCode)
        evil(item.convertCode)
      })
    },
    // 挽留弹窗点击确定
    detentionSubmit () {
      clearTimeout(this.pageStatusTimer)
      this.timeout && clearTimeout(this.timeout)
      this.handleSubmit()
    },
    // 处理抢购按钮
    handleSubmit () {
      // 路径三 用新支付弹窗
      if (
        this.configData.path_id === LANDING_PATH_MAP.PATH_THREE_OUT_WX &&
        !this.configData.showPopNew
      ) {
        this.configData.showPopNew = true
        return
      }

      if (
        this.components.filter(
          (item) => item.component_name === 'FormPop'
        )[0] &&
        !this.configData.showPop
      ) {
        // 路径2的表单是pop出表单的形式
        this.configData.showPop = true
      } else {
        const NEED_ADDREE_PATH = [
          PATH_ID.OUT_WX_4,
          PATH_ID.IN_WX_3
        ]
        // 新增逻辑 如果是实物订单打开收货地址
        if (NEED_ADDREE_PATH.includes(this.configData.path_id)) {
          this.addressPathSubmit()
        } else {
          this.submit()
        }
      }
    },
    addressPathSubmit () {
      // 先校验验证码
      const dom = this.$refs?.FormPay?.[0].$refs
      if (!vertifyPhoneCode({ form: this.form, dom, that: this })) return

      console.log('this.$refs.Address', this.$refs.Address)
      this.$refs.Address[0].visible = true
      this.$refs.Address[0].address_info.mobile = this.form.phone || localStorage.getItem('phone')
      this.$log.show({
        current_page: location.href,
        module_ori: '落地页-收货地址页展示',
        module_part: `106_luodiyehoutai_lcl_0030_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
    },
    // 用户选择支付类型
    choicePayType () {
      if (this.config.payType) {
        this.config.show = false
        this.submit()
      }
    },
    submit () {
      if (this.submitting) {
        return
      }
      this.submitting = true
      setTimeout(() => {
        this.submitting = false
      }, 1500)
      const fromName = this.$refs.FormPay || this.$refs.FormPop || this.$refs.FormPopNew
      let cityCodePreg = null
      if (fromName) {
        fromName[0].validateForm()
        fromName[0].noValidateCode(true)
        cityCodePreg = fromName[0].cityCodePreg
        this.form.area_code = fromName[0].cityCode || ''
      }

      const dom = this.$refs?.FormPay?.[0].$refs
      if (
        this.configData.form_pay.conceal_guard?.is_show &&
        this.configData.form_pay.conceal_guard?.needSelect &&
        !this.form.checked
      ) {
        this.$refs.agreementWrap.toggleShow()
        return
      }
      if (!vertifyPhoneCode({ form: this.form, dom, that: this, cityCodePreg })) return
      // 路径三校验
      if (this.pathId === LANDING_PATH_MAP.PATH_THREE_OUT_WX) {
        // 必选支付方式
        if (!this.config.payType) {
          this.$toast({
            message: '请选择支付方式'
          })
          return
        }
        // 隐私协议
        if (
          this.configData.new_form_pop.concealGuard?.is_show &&
          this.configData.new_form_pop.concealGuard?.needSelect &&
          !this.form.checked
        ) {
          this.$refs.agreementWrap.toggleShow()
          return
        }
      }
      // 提前校验验证码/是否购买(目的是：购买过的用户在选择支付方式前排除掉)
      this.$apis.landPage
        .postPayBeforeCheck({
          ...getSearch(),
          phone: this.form.phone,
          area_code: this.form.area_code,
          code: this.form.testCode,
          center_coupon_id: this.center_coupon_id,
          product_sn: this.product_sn_key,
          src_site_id: 0,
          ext_param: [
            {
              domain: 'landing_page',
              abKey: this.abResult.ab_key,
              group: this.abResult.ab_group,
              uniqFlag: this.abResult.uniq_user
            }
          ],
          tourist_id: window.tourist_id ? window.tourist_id : ''
        })
        .then(() => {
          this.$log.click({
            current_page: location.href,
            module_ori: '表单提交模块',
            module_part: `106_tf_h5_00037_${getSearch('id')}`,
            alternate: getSearch('alternate')
          })
          if (this.payTypes.length > 1 && !this.config.payType) {
            this.configData.showPop = false
            this.config.show = true
            return
          }
          // 关闭支付弹窗
          this.configData.showPopNew = false

          localStorage.setItem('phone', this.form.phone)
          localStorage.setItem('area_code', this.form.area_code)
          localStorage.setItem('testCode', this.form.testCode)

          // 0元课程落地页不配置支付方式，默认支付方式微信  且  没有支付方式
          if (COURSE_PRODUCT_SN.includes(this.configData.product_sn) && !this.configData?.pay_type?.pay_type?.length) {
            this.config.payType = 2
          }
          if (this.config.payType) {
            localStorage.setItem('payType', this.config.payType)
          }
          this.pay(
            Number(
              this.config.payType ||
                localStorage.getItem('payType') ||
                this.payTypes[0].value
            )
          )
        })
        .catch((e) => {
          this.handleError(e)
        })
    },
    pay (type) {
      // 支付
      // 兼容芒果回传
      const mgTv = getSearch('mga_report')
      if (mgTv) {
        sessionStorage.setItem('mga_report', mgTv)
        this.changeQuery('mga_report')
      }
      const phone = this.form.phone || localStorage.getItem('phone') || getSearch('phone')
      const area_code = this.form.area_code || localStorage.getItem('area_code') || getSearch('area_code')
      const url = location.href + `&checkOrder=1&phone=${phone}&area_code=${area_code}`
      const purchaseLimit = this.configData.purchase_limit
      const limitBuy = purchaseLimit ? purchaseLimit.limit_buy : ''
      this.$apis.landPage
        .postPayOut({
          phone,
          area_code,
          code: this.form.testCode,
          pay_type: type,
          path_id: this.pathId,
          coupon_id: this.coupon_id,
          src_site_id: 0,
          center_coupon_id: this.center_coupon_id,
          return_url: encodeURIComponent(url),
          ...this.device_info,
          ...getSearch(),
          product_sn: this.product_sn_key,
          limit_buy: limitBuy || '', // 购买限制
          ext_param: [
            {
              domain: 'landing_page',
              abKey: this.abResult.ab_key,
              group: this.abResult.ab_group,
              uniqFlag: this.abResult.uniq_user
            }
          ],
          address_info: {
            ...this.addressInfo
          },
          tourist_id: window.tourist_id ? window.tourist_id : ''
        })
        .then((data) => {
          // 判断是否选择支付方式, 添加埋点
          if (this.payTypes.length > 1 && this.config.payType) {
            const methods = {
              [PAY_TYPE_MAP.WX]: 'selectWX',
              [PAY_TYPE_MAP.ZFB]: 'selectAli'
            }
            this[methods[type]](data.order_num)
          }
          if (data.order_num) return this.goSuccess(data.order_num)
          if (type === 2) {
            window.location.href = decodeURIComponent(data.url)
          }
          if (type === 1) {
            const isReSet = document.getElementById('aliPay')
            if (isReSet) {
              isReSet.parentNode.removeChild(isReSet)
            }
            const targetDom = document.createElement('div')
            targetDom.id = 'aliPay'
            targetDom.innerHTML = data
            document.body.appendChild(targetDom)
            targetDom.children[0].submit()
          }
        })
        .catch((e) => {
          this.handleError(e)
        })
    },
    // 是否购买轮询
    pullBuyStatus () {
      if (!this.form.phone) {
        clearTimeout(this.pageStatusTimer)
        this.timeout && clearTimeout(this.timeout)
        return
      }

      this.$apis.landPage
        .checkBought({
          phone: this.form.phone,
          area_code: this.form.area_code || localStorage.getItem('area_code') || '',
          src_site_id: 0,
          ...getSearch(),
          product_sn: this.product_sn_key
        })
        .then(() => {
          // 没有报名弹出挽留弹窗(首次)
          // this.configData.showDialog = true
          if (!this.loading) {
            this.loading = this.$toast.loading({
              duration: 0,
              forbidClick: true,
              message: '支付中...'
            })
          }
          if (!this.timeout) {
            this.timeout = setTimeout(async () => {
              this.$toast.clear()
              const { order_num: orderNum } = await this.$apis.landPage.getPreNoPayOrder({
                phone: this.form.phone,
                area_code: this.form.area_code || localStorage.getItem('area_code')
              })
              this.configData.orderNum = orderNum
              this.configData.showDialog = true
            }, 3000)
          }
          this.pageStatusTimer = setTimeout(() => {
            this.pullBuyStatus()
          }, this.intervel)
        })
        .catch((e) => {
          this.configData.showDialog = false
          if (this.isCq) {
            if (CQ_BOUGHT_CODE.includes(e.data.code)) {
              // 获取到订单编号
              const { order_num } = e.data.data
              this.$toast.clear()
              clearTimeout(this.pageStatusTimer)
              this.timeout && clearTimeout(this.timeout)
              this.goSuccess(order_num)
              // 上报购买转化
              this.runConvertCode()
            }
          } else {
            if ([30006, 30005].includes(e.data.code)) {
              // 获取到订单编号
              const { order_num } = e.data.data
              this.$toast.clear()
              clearTimeout(this.pageStatusTimer)
              this.timeout && clearTimeout(this.timeout)
              this.goSuccess(order_num)
              // 上报购买转化
              this.runConvertCode()
            }
          }
        })
    },
    // 获取数据
    async getData () {
      const { host } = location
      // 如果没有id则根据域名显示默认落地页
      const defaultId = defaultPageId[host] || 406
      if (!getSearch('id') && defaultId === 758) {
        setDocumentTitle('课程已被抢光了～')
        this.$toast({
          message: '课程已被抢光了～'
        })
        return false
      }
      const alterData = JSON.parse(localStorage.getItem('ab_result')) || {}
      const {
        data = {},
        path_id,
        product_sn,
        product_sn_key,
        ab_result = {},
        brandTitle,
        brandId,
        coupon_id,
        center_coupon_id,
        center_coupon_info,
        product_price,
        goods_price,
        goods_original_price
      } = await this.$apis.landPage.getPageConfig({
        id: getSearch('id') || defaultId,
        ab_group: alterData.ab_group
      })
      setDocumentTitle(brandTitle)

      this.brandId = brandId
      this.pathId = path_id
      this.product_sn_key = product_sn_key
      this.coupon_id = coupon_id
      // 商品中心优惠券
      this.center_coupon_id = center_coupon_id
      // 优惠券详情
      this.centerCouponInfo = center_coupon_info
      this.centerCouponInfo.product_price = product_price
      this.product_price = product_price

      // 微信外，路径三，支付方式写死（暂未用到）
      if (this.pathId === LANDING_PATH_MAP.PATH_THREE_OUT_WX) {
        data.form_pay.pay_type = [
          '{"name":"微信支付","value":2}',
          '{"name":"支付宝支付","value":1}'
        ]
        // 路径三 不默认支付方式
        this.config.payType = null
      }

      Object.assign(this.configData, data, { product_sn_key, goods_price, goods_original_price })
      console.log('this.configData', this.configData, this.config)

      ab_result.product_sn = product_sn
      localStorage.setItem('ab_result', JSON.stringify(ab_result))
      this.components = path[this.pathId - 1].components.concat(data.component)

      this.abResult = ab_result
      this.$nextTick(() => {
        uploadImagePercent.call(this, '#landpage', '.landpage-bottom')
      })
    },
    // 已经阅读协议
    confirmAgreement () {
      scrollView(this, '.landpage-formpay')
      this.form.checked = true
      this.handleSubmit()
    },
    // 查看协议
    clickConcealGuard () {
      this.$log.show({
        current_page: location.href,
        module_ori: '落地页首页-隐私协议弹窗',
        module_part: `106_tf_ldyyinsi_00001_${getSearch('id')}`,
        alternate: getSearch('alternate')
      })
      this.configData.showConcealGuardDialog = true
    },
    // 协议内容已阅读
    concealGuardConfirm () {
      this.configData.showConcealGuardDialog = false
      this.confirmAgreement()
    },
    // 挽留弹窗 用户取消支付
    cancelpay () {
      if (this.configData.detention_dialog_again.is_show) {
        this.configData.showDialogAgain = true
      }
      this.form.checked = true
      localStorage.removeItem('payType')
      this.config.payType = null
      clearTimeout(this.pageStatusTimer)
      this.timeout && clearTimeout(this.timeout)
    },

    // 关闭支付弹窗
    closePopup () {
      this.configData.showPopNew = false
    },
    // 表单错误处理
    handleError ({ data: { code, msg, data } }) {
      if (!codeMap[code]) return
      let confirm = {
        message: msg,
        confirmButtonText: '确定'
      }
      if (code === 30006) {
        confirm.confirmButtonText = '查看分班'
        this.$toast('您已报名过此课程，自动跳转中')
        this.$log.click({
          current_page: location.href,
          ...codeMap[code].report,
          alternate: getSearch('alternate'),
          module_part: `${codeMap[code].report.module_part}_${getSearch('id')}`
        })
        setTimeout(() => {
          this.goSuccess(data.order_num)
        }, 1000)
        return
      }
      if (code === 33002) {
        this.$log.click({
          current_page: location.href,
          module_ori: '手机号、验证码表单',
          module_part: `106_tf_xinzeng_0006_${getSearch('id')}`,
          alternate: getSearch('alternate')
        })
      }
      if (code === 33002 && this.configData.form_pay.is_show) {
        this.$toast(msg)
        this.$log.click({
          current_page: location.href,
          ...codeMap[code].report,
          alternate: getSearch('alternate'),
          module_part: `${codeMap[code].report.module_part}_${getSearch('id')}`
        })
        const fromName = this.$refs.FormPay || this.$refs.FormPop
        if (fromName) {
          fromName[0].$refs.testCode.focus()
          fromName[0].noValidateCode(false)
        }
        return
      }
      let timeout = null
      // 满足复训条件用户和复训班级结算要求，但不在复训活动期内的用户
      if ([30023].includes(code)) {
        const { id } = getSearch()
        this.$log.show({
          current_page: location.href,
          module_ori: '落地页-重复购买弹窗',
          module_part: `106_tf_H5_00238_${id}`
        })
        confirm = {
          message: '您已经报名过啦！无需再次支付！\n现在为您直接跳过付费流程...',
          confirmButtonText: '【免费学习】'
        }
        timeout = setTimeout(() => {
          this.goJumpMiniSuccess()
        }, 3000)
      }
      this.$dialog(confirm).then((res) => {
        codeMap[code].report &&
          this.$log.click({
            current_page: location.href,
            ...codeMap[code].report,
            alternate: getSearch('alternate'),
            module_part: `${codeMap[code].report.module_part}_${getSearch(
              'id'
            )}`
          })
        if ([30006, 30005, 30009, 30010].includes(code)) {
          this.goSuccess(data.order_num)
        }
        // 复训
        if ([30023].includes(code)) {
          timeout && clearTimeout(timeout)
          this.goJumpMiniSuccess()
        }
      })
    },
    async goJumpMiniSuccess () {
      const { id, wm_click_id: wmClickId } = getSearch()
      this.$log.click({
        current_page: location.href,
        module_ori: '落地页-重复购买弹窗',
        module_part: `106_tf_H5_00239_${id}`
      })
      const PARAMS_ENV = {
        dev: {
          message_id: 1000726,
          activity_id: 1000725,
          site_id: 2179086,
          wm_click_id: wmClickId,
          id
        },
        prod: {
          message_id: 1003932,
          activity_id: 1003933,
          site_id: 5425426,
          wm_click_id: wmClickId,
          id
        }
      }
      const params = PARAMS_ENV[process.env.VUE_ENV]
      let routeName = 'second-result-toufang'
      try {
        await this.$apis.landPage.postPayOut({
          phone: this.form.phone || localStorage.getItem('phone'),
          code: this.form.testCode,
          ...params,
          src_site_id: getSearch().site_id || 0,
          product_sn: 'PRODUCT_SN_XIAOBAI_0_LIVE'
        })
      } catch (error) {
        const { msg, code } = error.data
        if ([39100, 39102].includes(code)) {
          this.$toast({
            message: msg
          })
          routeName = 'second-result-yongfu'
        }
      }
      params.routeName = routeName
      const APP_ID = 'wx793df71145857f27'
      const { url: miniUrl } = await this.$apis.landPage.getMiniHref({ appid: APP_ID, query: { ...params }, path: 'pages/retrain/retrain' })
      window.location.href = miniUrl
    },
    // 修改参数
    changeQuery (name, value) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (value) {
        query[name] = value
      } else {
        delete query[name]
      }
      this.$router.replace({
        ...this.$route,
        query: {
          ...query
        }
      })
    },
    // 保存弹窗返回的地址信息。用于图书投放支付
    handleAddressInfo (e) {
      this.$log.click({
        current_page: location.href,
        module_ori: '落地页-收货地址页-【立即支付】按钮',
        module_part: `106_luodiyehoutai_lcl_0031_${getSearch('id')}`,
        alternate: {
          ...(JSON.parse(getSearch('alternate')) || {}),
          address_info: e
        }
      })
      // 保存地址
      this.addressInfo = e
      // 关闭组建弹窗
      this.$refs.Address[0].visible = false
      this.submit()
    }
  }
}
</script>

<style lang="scss" scoped>
.landpage {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}
.red-content {
  position: relative;
  transform: scale(1);
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  &__title {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 20%;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: #9c551d;
  }
  &__price {
    position: absolute;
    z-index: 4;
    // top: 250px;
    left: 0;
    right: 0;
    font-size: 120px;
    line-height: 120px;
    font-family: SanFrancisco;
    text-align: center;
    margin: auto;
    margin-top: 36%;
    display: flex;
    font-weight: 700;
    align-items: flex-end;
    justify-content: center;
    color: #ea3e43;
  }
  &__yuan {
    z-index: 3;
    width: 50px;
    height: 47px;
    margin-bottom: 10px;
    background-image: url("https://wm-front-common.oss-cn-beijing.aliyuncs.com/wm-front-landing-page-mobile/yuan.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.redPocket {
  background: transparent;
  overflow: hidden;
  width: 750px;
  height: 742px;
  top: 50%;
  left: 50%;
}
.animateRedPocket {
  animation: redPocket 1.2s ease-in 1s 1 forwards;
}
.animateRedContent {
  animation: redContent 1.2s ease-in 1s 1 forwards;
}
@keyframes redPocket {
  0% {
    top: 50%;
    left: 50%;
  }
  100% {
    top: 120%;
    left: 50%;
  }
}
@keyframes redContent {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
</style>
