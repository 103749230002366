
// 不同域名默认主页
export const defaultPageId = {
  'info.cqslim.net': 758,
  'test-info.cqslim.net': 758,
  'info.shanyuer.com': 774,
  'test-info.shanyuer.com': 774,
  'info.imeizhi.top': 1168,
  'imeizhi.top': 1168,
  'test-info.imeizhi.top': 1151,
  'info.healthygo.cn': 1704,
  'test-info.healthygo.cn': 1351,
  'cqslimjn.weiruism.com': 1673,
  'cqslim.weiruism.com': 1673,
  'info.bjkeyuan.cn': 1804,
  'test-info.bjkeyuan.cn': 1420,
  'info.jiankangren.top': 2351,
  'test-info.jiankangren.top': 2015,
  'test-info.cqdw.top': 2058,
  'info.cqdw.top': 2559,
  'tf.weimiaotj.cn': 2603,
  'test-tf.weimiaotj.cn': 2073,
  'info.hujnutrition.cn': 2965,
  'test-info.hujnutrition.cn': 2239,
  'info.huolihk.com': 3098,
  'test-info.huolihk.com': 2296,
  'cq.cd.weizhuziran.com': 3405,
  'cq.schyxll.com': 3438,
  'info.yinianjiankang.cn': 3585,
  'info.chiyujiankang.cn': 3586,
  'info.shiyujiankang.cn': 3587,
  'info.wanwuzhili.com': 4675
}

// 小程序appid
export default {
  dev: {
    wxConfig: {
      appid: 'wxa4c75143addfe517'
    },
    pcHost: ''
  },
  prod: {
    wxConfig: {
      appid: 'wxa4c75143addfe517'
    },
    pcHost: ''
  }
}

// 不同业务线的分享文案
const CAISHANG = {
  share: {
    title: '微淼12天小白理财营', // 分享标题
    desc: '12天12课，让你轻松赢得睡后收入', // 分享描述
    imgUrl: 'http://wm-front-common.oss-cn-beijing.aliyuncs.com/wm-front-landing-page-mobile/favicon.ico',
    link: location.href
  }
}
const CANGQING = {
  share: {
    title: '我正在体验专业的营养师服务，邀你一起', // 分享标题
    desc: '告别高价保健品，长轻营养食疗7天调理营带你通过新的科学调理方式，享受健康生活', // 分享描述
    link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: 'https://cq-front-common.cqslim.com/common/live-mobile/live-share-img.jpg' // 分享图标
  }
}
const MANAGE = {
  share: {
    title: '锻炼管理底层逻辑', // 分享标题
    desc: '卓越管理体验营开课啦，5天升级管理思维', // 分享描述
    imgUrl: 'http://wm-front-common.oss-cn-beijing.aliyuncs.com/wm-front-landing-page-mobile/favicon.ico',
    link: location.href
  }
}
const SHANYER = {
  share: {
    title: '善育儿家长服务', // 分享标题
    desc: '善育儿，提供优秀孩子养育工具箱和服务，打造幸福力家庭！', // 分享描述
    imgUrl: 'https://wm-front-common.oss-cn-beijing.aliyuncs.com/wm-front-landing-page-mobile/syer_logo.png',
    link: location.href
  }
}
// 品牌id
export const SP_CONFIG = {
  11: CANGQING,
  12: CAISHANG,
  9: CAISHANG,
  16: MANAGE,
  18: SHANYER,
  19: CANGQING,
  20: SHANYER
}

// 0或1元财商的专有分享文案
export const CAISHANG_1YUAN = {
  share: {
    title: '微淼教育限时活动报名入口', // 分享标题
    desc: '请务必点击此程序进行报名，活动限时限量，报名后请一定扫码添加老师微信！！', // 分享描述
    imgUrl: 'https://wm-front-common.weimiaocaishang.com/wm-front-landing-page-mobile/share_logo.png',
    link: location.href
  }
}

const { origin } = window.location
export const manageAppid = 'wx30e528deb8559947'
export const yuerAppid = 'wx0775466a78af23e3'
export const meiZhiAppid = 'wxb3a0b19f0e45d3bc'
export const caishangQuestionUrl = origin + '/customer/?fromId=3' + '#/Question'
export const changqingAppid = process.env.VUE_ENV === 'dev' ? 'wx51689cfc350a7925' : 'wxd0faa28b2fb76386'
export const manageQuestionUrl = origin + '/customer/' + '#/ManageQuestion'
export const changqingQuestioUrl = origin + '/#/questionnaire'
export const miniEnv = process.env.VUE_ENV === 'dev' ? 'trial' : 'release'
export const feedbackUrl = process.env.VUE_ENV === 'dev' ? 'https://test-info.weimiaoshangxueyuan.top/customer/#/Feedback?sceneId=4' : 'https://tf.weimiaotj.cn/customer/#/Feedback?sceneId=4'
export const OSS_HOST = 'https://wm-front-common.weimiaocaishang.com/wm-front-landing-page-mobile/'
